<template>
  <p class="mb-3 fw-bolder tw-text-size20">
    <span class="text-primary">* 未結單金額 {{ unCheckOutMoney }} 元</span
    ><span class="text-danger"> (金額僅供參考，實際金額以結單畫面為主)</span>
  </p>
  <!-- 儲值回報 -->
  <MoneyReport :participantId="participantId"></MoneyReport>
  <hr />
  <!-- 購物金歷史紀錄 -->
  <WalletHistory :participantId="participantId"></WalletHistory>
</template>

<script>
// components
import MoneyReport from '../../../components/shopOverview/MoneyReport.vue'
import WalletHistory from '../../../components/shopOverview/WalletHistory.vue'

export default {
  components: {MoneyReport, WalletHistory},
  data() {
    return {
      // data
      serverToken: '',
      storeId: 0,
      participantId: 0,
      unCheckOutMoney: 0,
    }
  },
  created() {
    this.initialization()
    this.getOrders()
  },
  methods: {
     // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.participantId = parseInt(this.$route.params.participantId)
      console.log(this.participantId)
    },
     // 取得顧客訂單
    getOrders() {
      this.$methods.switchLoading('show')
      const vm = this
      const getOrdersApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      let merchOrderQuery = {"columns":[{"columnName":"id","siteObjectType":26}],"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":26},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"buyerId","siteObjectType":26},"operator":{"operator":"=","type":1,"value":this.participantId}}],"operator":1},"siteObjectType":26,"sqlCommandType":1}
      const data = [{
        query: merchOrderQuery,
        methods: '{getCheckOutQuantity}'
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: getOrdersApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchOrders = res.data[0].objects
            let unCheckOutMoney = 0
            merchOrders.forEach(item => {
              unCheckOutMoney += vm.$methods.numberToFixed((item.quantity - item.checkOutQuantity) * item.price)
            })
            vm.unCheckOutMoney = vm.$methods.numberToFixed(unCheckOutMoney)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    }
  }
}
</script>